<template>
  <div class="d-flex-column main" :class="filterVisible ? 'min-1000' : null">
    <div class="flex-1 main">
      <div class="mw-50p ml-0 mt-0">
        <h3 class="mt-0">Find your place to get involved</h3>
        <!-- MAIN FILTER -->
        <el-form @submit.native.prevent>
          <!-- <el-autocomplete
      class="inline-input"
      v-model="searchInput"
      :fetch-suggestions="fetchSearchData"
      placeholder="Please Input"
      @select="handleSelect"
    ></el-autocomplete> -->

          <el-input
            id="searchInput"
            placeholder="Try a location or club name"
            v-model="searchInput"
            class="full-width"
          >
            <template slot="append">
              <button class="button-primary icon-wrapper" @click.prevent="fetchSearchData">
                <i class="icon-search"></i>
              </button>
            </template>
          </el-input>
        </el-form>
      </div>
      <!--  SUB FILTERS    -->
      <div v-if="showFilterButtons" class="filters d-flex">
        <!-- below copied from element -->
        <div class="el-select select-1" :class="concatFilters.length !== 0 ? 'has-value' : null">
          <div
            class="el-input el-input--suffix"
            :class="filterVisible ? 'visible' : ''"
            @click="toggleVisible(true)"
          >
            <input
              type="text"
              readonly="readonly"
              autocomplete="off"
              :placeholder="filterText"
              class="el-input__inner"
            />
            <span class="el-input__suffix">
              <span class="el-input__suffix-inner">
                <i class="el-select__caret el-input__icon icon-arrow-down"></i>
              </span>
            </span>
          </div>
          <ModalLayoutRelative
            v-if="filterVisible"
            class="modal-parent"
            @close="handleCancelFilters"
            title="FILTERS"
          >
            <FilterPage
              @submit="handleSubmitFilters"
              :value="appliedFiltersSync"
              @cancel="handleCancelFilters"
              @clear="handleClearFilters"
            />
          </ModalLayoutRelative>
          <div
            class="append-icon-close"
            v-show="concatFilters.length !== 0"
            @click="handleClearFilters"
          >
            <i class="icon-close-green"></i>
          </div>
        </div>
        <div v-if="hasPrograms" class="select-2">
          <el-select
            v-model="lengthValue"
            placeholder="DURATION"
            class="full-width"
            :class="lengthValue ? 'has-value' : null"
          >
            <el-option
              v-for="item in lengthChoices"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="append-icon-close" v-show="lengthValue" @click="lengthValue = ''">
            <i class="icon-close-green"></i>
          </div>
        </div>
        <div v-if="hasPrograms" class="select-3">
          <el-select
            v-model="daysValue"
            placeholder
            class="mt-0 text-days full-width"
            multiple
            :style="dayOfTheWeekStyle"
            :class="daysValue.length !== 0 ? 'has-value' : null"
          >
            <el-option
              v-for="item in daysChoices"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="append-icon-close" v-show="daysValue.length !== 0" @click="daysValue = []">
            <i class="icon-close-green"></i>
          </div>
        </div>
      </div>
      <div class="main padding-lr-10-sm-and-up">
        <!-- important: area for search results   -->
        <Card
          :id="`clubsearch_${i}`"
          v-for="(object, i) in entities"
          :object="object"
          :key="`${object._id}+${i}`"
          @submit="submit(object)"
        />
      </div>
    </div>
    <div class="padding-lr-10-sm-and-up">
      <el-card class="mt-2 d-flex-column flex-0 align-text-center" v-if="cantFind">
        <div class="flex-1 align-text-center m-1">Can't find what you are looking for?</div>
        <el-button
          @click="handleCantFind"
          type="success"
          class="full-width button-light button-thin mw-300"
          >Get in contact</el-button
        >
      </el-card>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import flow from "lodash/fp/flow";
import map from "lodash/fp/map";
import flatMap from "lodash/fp/flatMap";
import reduce from "lodash/fp/reduce";
import find from "lodash/fp/find";
import filter from "lodash/fp/filter";
import omitBy from "lodash/fp/omitBy";
import isEmpty from "lodash/isEmpty";
import range from "lodash/range";
import { mapActions, mapGetters } from "vuex";
import Card from "@/components/card/Card.vue";
import {
  daysChoices,
  lengthChoices,
  ageLevels,
  memberTypes,
  activity,
  competitionType,
  gender,
} from "@/utils/constants";
import ModalLayoutRelative from "@/layouts/ModalLayoutRelative.vue";
import FilterPage from "@/components/filters/FilterPage.vue";
import msg from "@/utils/constants/msg";

const appliedFiltersOriginal = {
  participantTypes: [],
  activities: [],
  genders: [],
  ages: [],
  competitionTypes: [],
};

export default {
  name: "ClubSearch",
  components: {
    FilterPage,
    ModalLayoutRelative,
    Card,
  },
  props: {
    criteria: String,
    type: Array,
    activity: Array,
    gender: String,
    agemin: Number,
    agemax: Number,
    comptype: Array,
    source: String,
    entity: Boolean,
    entitytype: String,
  },
  computed: {
    ...mapGetters("user", ["isAuthenticated"]),
    ...mapGetters("registration", ["current"]),
    concatFilters() {
      const a = Object.values(this.appliedFiltersReal);
      return a.reduce((total, item) => total.concat(item), []);
    },
    filterText() {
      const result = this.concatFilters;
      if (result.length === 1 && result[0] !== "All") {
        return result[0].toUpperCase();
      }
      return "FILTER";
    },
    dayOfTheWeekStyle() {
      const { daysValue } = this;
      const txt = daysValue.length === 1 ? daysValue[0] : "DAY OF THE WEEK";

      return {
        "--content": `"${txt}"`.toUpperCase(),
        "--color": daysValue.length !== 0 ? "#098255" : "grey",
      };
    },
    currentAgeRange() {
      const ageLevel = find((o) => o.name === this.appliedFiltersReal.ages[0])(ageLevels);
      if (ageLevel) return { min: ageLevel.min, max: ageLevel.max };
      return {};
    },
    mappedParticipantType() {
      return flow(
        flatMap((val) => filter((o) => o.name.split(" ")[0] === val)(memberTypes)),
        map((obj) => obj.type)
      )(this.appliedFiltersReal.participantTypes);
    },
    showFilterButtons() {
      return this.entities.length !== 0 || this.concatFilters.length !== 0;
    },
    hasPrograms() {
      return reduce((entity) => entity.entityType === "program")(this.entities).length !== 0;
    },
  },
  methods: {
    ...mapActions("registration", ["updateCurrent"]),

    cloneDeep,
    handleCantFind() {
      // Expression of Interest for National Bodies
      this.$router
        .push({
          name: "expressinterest",
          params: { entityType: "national", entityId: 1 },
        })
        .catch(() => {});
    },
    submit(object) {
      this.updateCurrent({ entity: object });

      // Programs don't have regoOpen
      if (object.entityType === "program" && this.isAuthenticated) {
        this.$router.push({ name: "chooseprofile" });
      } else if (object.regoOpen && !object.teamInManyComps && this.isAuthenticated) {
        this.$router.push({ name: "chooseprofile" });
      } else if (object.regoOpen && !object.teamInManyComps) {
        this.$router
          .push({
            name: "loginrequired",
            params: { loginRedirect: "/register/chooseprofile" },
          })
          .catch((e) => {
            //   console.log("error");
          });
      } else if (object.entityType === "program") {
        this.$router
          .push({
            name: "loginrequired",
            params: { loginRedirect: "/register/chooseprofile" },
          })
          .catch((e) => {
            //  console.log("error");
          });
      } else {
        // Expression of Interest for Entities
        this.$router.push({
          name: "expressinterest",
          params: { entityType: object.entityType, entityId: object._id },
        });
      }
    },
    async fetchSearchData() {
      this.$store.commit("root/LOADING", true);
      this.cantFind = true;
      const nationalOrg = this.$store.getters["views/nationalOrg"];
      const body = omitBy(isEmpty)({
        source: nationalOrg ? nationalOrg.code : undefined,
        criteria: this.searchInput.split(",")[0],
        filters: omitBy(isEmpty)(
          Object.assign(
            {},
            this.appliedFiltersReal,
            { ages: this.currentAgeRange },
            { participantTypes: this.mappedParticipantType }
          )
        ),
        entityType: this.entityType,
      });

      body.isEntityIdSearch = this.isEntityIdSearch;

      this.$store.commit("root/LOADING", true);

      // try {
      //   const response = await this.$httptoken.post(
      //     "/nrl/api/v1/portal-public/registration/search",
      //     body
      //   );
      //   //        const response = await this.$httpms.post("/searchtoPlay", body);
      //   if (this.isEntityIdSearch && this.entityType === "program") {
      //     this.searchInput = "";
      //   }
      //   if (this.isEntityIdSearch) this.isEntityIdSearch = false;
      //   const result = response.data;
      //   if (result && result.data && result.data.length !== 0) {
      //     console.log("yup got results");
      //     // this.entities = result.data;
      //     this.entities = result.data.filter((x) =>
      //       ["program", "club", "association", "team"].includes(x.entityType)
      //     );
      //   } else {
      //     this.entities = [];
      //   }
      //   this.$store.commit("root/LOADING", false);
      // } catch (e) {
      //   this.$store.commit(
      //     "views/PUSH_NOTIFICATION",
      //     {
      //       msg: msg.error.apiError,
      //       type: "warning",
      //     },
      //     { root: true }
      //   );
      //   this.entities = [];
      //   this.$store.commit("root/LOADING", false);
      // }
      this.$store.commit("views/CLEAR_NOTIFICATION", null, { root: true });
      this.$store.commit("root/LOADING", true);
      this.$httptoken
        .post("/nrl/api/v1/portal-public/registration/search", body)
        .then((response) => {
          if (this.isEntityIdSearch && (this.entityType === "program" || body.entityType === "team")) {
            this.searchInput = "";
          }
          if (this.isEntityIdSearch) this.isEntityIdSearch = false;
          const result = response.data;
          if (result && result.data && result.data.length !== 0) {
            this.entities = result.data;
            //            this.entities = result.data.filter(x => ['program', 'club', 'association', 'team'].includes(x.entityType));
          } else {
            this.entities = [];
          }
          this.$store.commit("root/LOADING", false);
        })
        .catch(() => {
          this.$store.commit(
            "views/PUSH_NOTIFICATION",
            {
              msg: msg.error.apiError,
              type: "warning",
            },
            { root: true }
          );
          this.entities = [];
          this.$store.commit("root/LOADING", false);
        });
    },
    querySearchAsync(queryString, cb) {
      const { searchResult } = this;
      const results = queryString
        ? searchResult.filter(this.createFilter(queryString))
        : searchResult;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        cb(results);
      }, 500 * Math.random());
    },
    createFilter(queryString) {
      return (link) => link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
    },
    handleInput(item) {
      this.filterValue = item;
    },
    handleSubmitFilters(val) {
      this.appliedFiltersReal = cloneDeep(val);
      this.appliedFiltersSync = cloneDeep(val);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    handleCancelFilters() {
      this.appliedFiltersSync = cloneDeep(this.appliedFiltersReal);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    handleClearFilters() {
      this.appliedFiltersReal = cloneDeep(appliedFiltersOriginal);
      this.appliedFiltersSync = cloneDeep(appliedFiltersOriginal);
      this.toggleVisible(false);
      this.fetchSearchData();
    },
    toggleVisible(to) {
      this.filterVisible = to;
    },
  },
  mounted() {
    this.isEntityIdSearch = this.entity;
    if(this.$route.query.entityType) {
      this.entityType = this.$route.query.entityType;
    }

    const skeleton = cloneDeep(appliedFiltersOriginal);
    if (this.current.type) {
      switch (this.current.type) {
        case "player":
          skeleton.participantTypes.push("Player");
          break;
        case "coach":
          skeleton.participantTypes.push("Coach", "Trainer", "Volunteer");
          break;
        case "referee":
          skeleton.participantTypes.push("Referee");
          break;
        case "reregister":
        default:
      }
    } else {
      this.updateCurrent({ type: "player" });
    }
    if (this.criteria) {
      this.searchInput = this.criteria;
    }

    if (this.type) {
      const mappedTypes = memberTypes.filter((t) => this.type.includes(t.type)).map((t) => t.name);
      if (mappedTypes.length > 0) {
        skeleton.participantTypes.push(...mappedTypes);
        this.updateCurrent({ type: mappedTypes[0] });
      } else {
        this.updateCurrent({ type: "player" });
      }
    } else {
      this.updateCurrent({ type: "player" });
    }
    if (this.activity) {
      const foundActivities = this.activity.filter((param) => activity.includes(param));
      if (foundActivities.length > 0) {
        skeleton.activities.push(...foundActivities);
      }
    }
    if (this.gender) {
      const foundGender = gender.find((a) => a === this.gender);
      if (foundGender) skeleton.genders.push(foundGender);
    }
    if (this.agemin || this.agemax) {
      let ageLevel;
      if (this.agemin && this.agemax) {
        ageLevel = find((o) => o.min >= this.agemin && o.max <= this.agemax)(ageLevels);
      } else if (this.agemin) {
        ageLevel = find((o) => range(o.min, o.max + 1).includes(this.agemin))(ageLevels);
      } else if (this.agemax) {
        ageLevel = find((o) => range(o.min, o.max + 1).includes(this.agemax))(ageLevels);
      }
      if (ageLevel) skeleton.ages.push(ageLevel.name);
    }
    if (this.comptype) {
      const mappedCompTypes = this.comptype
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .filter((type) => competitionType.includes(type));
      if (mappedCompTypes.length > 0) {
        skeleton.competitionTypes.push(...mappedCompTypes);
      }
    }

    this.$store.dispatch("views/updateNationalOrg", this.source);

    if (this.entitytype) {
      this.entityType = this.entitytype;
    }

    this.appliedFiltersOriginal = skeleton;
    this.appliedFiltersSync = skeleton;
    this.appliedFiltersReal = skeleton;

    if (this.searchInput) {
      this.fetchSearchData();
    }
  },
  data() {
    return {
      cantFind: false, // After search has been conducted
      // Search
      searchInput: "",
      searchTimeout: null,
      searchResult: [],
      // Filters
      filterVisible: false,
      filterValue: "",
      appliedFiltersOriginal,
      appliedFiltersSync: appliedFiltersOriginal, // Checkboxes
      appliedFiltersReal: appliedFiltersOriginal,
      lengthChoices,
      lengthValue: "",
      daysChoices,
      daysValue: [],
      entities: [],
      isEntityIdSearch: false,
      entityType: "",
    };
  },
};
</script>

<style lang="scss">
.text-days::before {
  content: var(--content);
  color: var(--color);
  text-transform: uppercase;
  position: absolute;
  font-family: $fontRegular;
  font-size: 0.875rem;
  top: 10px;
  left: 15px;
  size: 0;
  z-index: 1;
}
</style>

<style lang="scss" scoped>
.append-icon-close {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
  color: $secondary;
}

.has-value {
  // make it a cross
  ::v-deep i.icon-arrow-down {
    background: none;
  }

  i.icon-arrow-down {
    background: none;
  }

  ::v-deep .el-input__inner {
    height: calc(0.875rem + 26px);
    border-color: $secondary;
    color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }

  .el-input__inner {
    border-color: $secondary;

    &::placeholder {
      color: $secondary !important;
    }
  }
}

::v-deep .el-select {
  height: calc(0.875rem + 26px);
}

.el-input {
  .el-input__suffix {
    position: absolute;
    right: 8px;
    transition: 0.5s;
  }
  &.visible {
    .el-input__suffix {
      transform: rotate(-180deg);
    }
  }
}

.select-1 {
  position: relative;
  width: 100%;
  @media (min-width: $sm) {
    max-width: 250px;
  }
}

.select-2 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.select-3 {
  position: relative;
  width: 50%;
  @media (min-width: $sm) {
    max-width: 200px;
  }
}

.filters {
  background-color: white;
  flex-wrap: wrap;
}

.min-1000 {
  min-height: 1400px;
}

::v-deep .el-tag {
  display: none;
}

::v-deep .el-input-group__append {
  padding: 0;
  border: 0;
}

.icon-wrapper {
  height: 40px;
  width: 55px;
}

.mw-50p {
  width: 100%;
  @media (min-width: 1263px) {
    max-width: 50%;
  }
}
</style>
